@font-face {
  font-family: pilat;
  src: url(../../Pilat\ Wide\ Book.otf);
}
@font-face {
  font-family: remake;
  src: url(../../Remake-Book_V0.1.ttf);
}
.hamburger-react {
  display: none;
}
a {
  text-decoration: none;
  color: white;
}
.pages {
  color: #316685;
  text-align: center;
  font-size: calc(1.5rem + 2vw);
  margin-top: 10%;
}

.navbar {
}

.nav-colored {
  background-color: #fff;
}
.nav-transparent {
  background-color: #fff;
}
.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  /* height: 80px; */
}

.main-container {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
.navSocial {
  position: relative;
  top: 5px;
  width: 24px;
  height: auto;
}
.nav-logo {
  position: relative;
  left: 5vw;
  /* top: 6vh; */
}
.bar__logo {
  width: 182px;
  height: auto;
  position: relative;
  top: 14px;
  margin: 0 !important;
}
.bar__logo2 {
  width: 182px;
  height: auto;
  position: relative;

  top: 0px;
  /* margin: 0 !important; */
}
.nav-menu {
  display: flex;
  list-style: none;
  align-items: center;
  position: absolute;
  right: 3vw;
  top: 10px;
  margin: 0 !important;
}

.nav-links {
  color: black;
  text-decoration: none;
  font-size: 0.85rem !important;
  font-family: remake !important;
}
.nav-links2 {
  text-decoration: none;
  color: white;
  font-family: remake !important;
  font-size: 0.85rem !important;
}
.navbar {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed !important;
  top: 0;
  z-index: 1000;
}
.colorChange {
  /* height: auto; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed !important;
  top: 0;
  z-index: 1000;
  background-color: rgb(255, 255, 255) !important;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.discTop {
  background-color: rgb(255, 255, 255) !important;
  /* width: 184px; */
  height: auto;
  text-align: center;
  padding: 6px 36px 6px 36px;
  line-height: 150%;
  color: black;
  font-family: remake;
  /* line-height: 15px; */
  font-size: 0.85 !important;
  letter-spacing: 0.1em;
}

.discTop2 {
  background-color: rgb(0, 0, 0);
  /* width: 184px; */
  height: auto;
  text-align: center;
  padding: 6px 36px 6px 36px;

  line-height: 150%;
  color: white;
  font-family: remake;
  /* line-height: 15px; */
  font-size: 0.85 !important;
  letter-spacing: 0.1em;
}

.nav-links__left {
  text-decoration: none;
  height: 100%;
  border-bottom: 3px solid transparent;
}
.fa-code {
  margin-left: 1rem;
}

.nav-item {
  /* font-size: 2vh; */
  margin-right: 24px;
  font-family: alt !important;
  position: relative;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-icon {
  display: none;
}
.btn__appbar {
}
.btn-text {
  transform: skewX(15deg) !important;
}
.nav__width {
}
.nav-links__right {
  font-size: 3vh !important;
  display: none;
}
/* 
div{
line-height: 170%;
letter-spacing: 6%;
}
span{
  line-height: 170%;
  letter-spacing: 6%;
}
p{
  line-height: 170%;
  letter-spacing: 6%;
} */
.responsive__menu {
  display: none;
}
.responsive__menu__hr {
  display: none;
}
.nav-icon {
  display: none;
}
.nav-icon2 {
  display: none;
}
@media screen and (max-width: 800px) {
  .nav-logo {
    position: relative;
    left: 75% !important;
    transform: translateX(-50%) !important;
  }
  .colorChange {
    height: 6.5vh !important;
  }
  .bar__logo2 {
    width: 40vw;
    height: auto;
    position: relative;
    top: 0px !important ;
  }
  .bar__logo {
    width: 40vw;
    height: auto;
    top: 0px !important;
  }
  .nav-menu {
    display: none;
  }
  .nav-links__left {
    display: none;
  }
  .btn__appbar {
    color: #fff;
    position: relative;
    background-color: #fc0071 !important;
    transform: skew(-15deg);
    border: 1.3px solid white !important;
    right: 1.6rem !important;
  }
  .btn-text {
    transform: skewX(15deg) !important;
  }
  .nav-menu.active {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    height: 100vh;
    position: absolute;
    padding-top: 5vh !important;
    top: 8.4vh !important;
    right: 0vw;
    background: white;
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
    opacity: 1;
    text-align: left !important;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadePot {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @-moz-keyframes fadePot {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes fadePot {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @-o-keyframes fadePot {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @-ms-keyframes fadePot {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .nav-item {
    display: none;
  }
  .responsive__menu {
    margin-right: auto;
    display: flex;
    text-align: left !important;
    align-items: center !important;
    justify-content: flex-start !important;
  }
  ul {
    padding: 0 !important;
  }
  .menu_news {
    font-family: remake;
    padding-left: 10vw;
    line-height: 170%;
    letter-spacing: 6%;
    font-size: 1.66vh;
    padding-bottom: 3vh;
  }
  .responsive__menu__social {
    width: 50vw;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 3vw !important;
  }
  .responsive__menu__hr {
    display: flex;
    color: black;
    background-color: black;
    width: 80% !important;
  }
  .socialMenu {
    margin: 3vh 0 3vh 0;
    width: 8vw;
    height: auto;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }
  .nav-links__right {
    padding: 1.5rem;

    display: table;
    color: white;
    text-decoration: none;
    padding: 1.5rem;

    border-bottom: 3px solid transparent;
  }
  .nav-icon {
    display: block;
    position: absolute;
    top: 16px !important;
    right: 5vw;

    cursor: pointer;

    /* display: none; */
  }
  .nav-icon2 {
    display: block;
    position: absolute;
    /* margin-bottom: 10px !important; */
    top: 16px !important;
    right: 5vw;

    cursor: pointer;
    z-index: 100;
  }

  .nav__width {
    width: 100%;
  }
  .hamburger-react {
    top: 2px !important;
    height: 50px !important;
    right: 20px;
  }

  .nav-container {
    /* padding-bottom: 10px !important; */
  }
}
/* --------------------------------------------------------------------------------------- */
@media screen and (width: 1024px) {
  .hamburger-react {
    height: 100px !important;
  }
  .bar__logo {
    width: 40vw;
    top: -10px !important;
    height: auto;
  }
  .bar__logo2 {
    width: 40vw;
    top: -10px;
    height: auto;
  }
}
@media screen and (max-width: 1024px) {
  .nav-container {
    padding: 20px 0 10px 0 !important;
  }
  .colorChange {
    padding: 20px 0 10px 0 !important;
  }
  .nav-logo {
    position: relative;
    left: 75% !important;
    transform: translateX(-50%) !important;
  }

  .nav-item {
    font-size: 2vh !important;
  }

  .bar__logo {
    width: 40vw;
    top: 10px;
    height: auto;
  }
  .bar__logo2 {
    width: 40vw;
    top: -10px;
    height: auto;
  }
  .hamburger-react {
    top: 10px;
    height: 90px;
    right: 20px;
    display: flex;
  }
  .nav-menu {
    display: none;
  }
  .discTop {
    background-color: rgb(255, 255, 255);
    width: 20vw;
    height: auto;
    font-size: 1.5vh !important;
    text-align: center;
    padding: 1vh 1.5vh 0.8vh 1.5vh;
    color: white;
  }
  .nav-menu.active {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    height: 100vh;
    position: absolute;
    padding-top: 5vh !important;
    top: 7.8vh;
    right: 0vw;
    background: white;
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
    opacity: 1;
    text-align: left !important;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .nav-item {
    display: none;
  }
  .responsive__menu {
    margin-right: auto;
    display: flex !important;
    text-align: left !important;
    align-items: center !important;
    justify-content: flex-start !important;
  }
  ul {
    padding: 0 !important;
  }
  .menu_news {
    font-family: remake;
    padding-left: 10vw;
    line-height: 170%;
    letter-spacing: 6%;
    font-size: 16px;
    padding-bottom: 3vh;
  }
  .responsive__menu__social {
    width: 50vw;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 3vw !important;
  }
  .responsive__menu__hr {
    display: flex;
    color: black;
    background-color: black;
    width: 80% !important;
  }
  .socialMenu {
    margin: 3vh 0 3vh 0;
    width: 8vw;
    height: auto;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }
  .nav-links__right {
    padding: 1.5rem;

    display: table;
    color: white;
    text-decoration: none;
    padding: 1.5rem;

    border-bottom: 3px solid transparent;
  }
  .colorChange {
    height: 6.5vh !important;
  }
  .nav-icon {
    display: block;
    position: absolute;
    top: 18px;
    right: 5vw;
    width: 5vw;
    height: auto;

    cursor: pointer;

    /* display: none; */
  }
  .nav-icon2 {
    display: block;
    position: absolute;
    top: 18px;
    right: 5vw;
    width: 5vw;
    cursor: pointer;
    z-index: 100;
  }
}

@media screen and (max-width: 820px) {
  .nav-logo {
    position: relative;
    left: 75% !important;
    transform: translateX(-50%) !important;
  }
  .nav-menu {
    display: none;
  }
  .bar__logo2 {
    width: 40vw;
    height: auto;
    position: relative;
    /* top: 2px !important; */
  }
  .bar__logo {
    width: 40vw;
    height: auto;
    /* top: 15px !important; */
  }
  .nav-menu.active {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    height: 100vh;
    position: absolute;
    padding-top: 5vh !important;
    top: 10vh;
    right: 0vw;
    background: white;
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
    opacity: 1;
    text-align: left !important;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .nav-item {
    display: none;
  }
  .responsive__menu {
    margin-right: auto;
    display: flex;
    text-align: left !important;
    align-items: center !important;
    justify-content: flex-start !important;
  }
  ul {
    padding: 0 !important;
  }
  .menu_news {
    font-family: remake;
    padding-left: 10vw;
    line-height: 170%;
    letter-spacing: 6%;
    font-size: 1.66vh;
    padding-bottom: 3vh;
  }
  .responsive__menu__social {
    width: 50vw;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 3vw !important;
  }
  .responsive__menu__hr {
    display: flex;
    color: black;
    background-color: black;
    width: 80% !important;
  }
  .socialMenu {
    margin: 3vh 0 3vh 0;
    width: 8vw;
    height: auto;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }
  .nav-links__right {
    padding: 1.5rem;

    display: table;
    color: white;
    text-decoration: none;
    padding: 1.5rem;

    border-bottom: 3px solid transparent;
  }
  .nav-icon {
    display: block;
    position: absolute;
    /* top: 1.5vh !important; */
    right: 5vw;
    width: 5vw;
    height: auto;

    cursor: pointer;

    /* display: none; */
  }
  .nav-icon2 {
    display: block;
    position: absolute;
    /* top: 1.5vh; */
    right: 5vw;
    width: 5vw;
    cursor: pointer;
    z-index: 100;
  }
}
@media screen and (max-width: 912px) {
  .nav-logo {
    position: relative;
    left: 75% !important;
    transform: translateX(-50%) !important;
  }
  .nav-menu {
    position: relative;
    /* top: 2vh; */
    left: 0vw;
  }
  .bar__logo2 {
    width: 40vw;
    height: auto;
    position: relative;
    /* top: 2px !important; */
  }
  .bar__logo {
    width: 40vw;
    /* top: 15px !important; */
    height: auto;
  }
  .nav-menu.active {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    height: 100vh;
    position: absolute;
    padding-top: 5vh !important;
    top: 10vh;
    right: 0vw;
    background: white;
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
    opacity: 1;
    text-align: left !important;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .nav-item {
    display: none;
  }
  .responsive__menu {
    margin-right: auto;
    display: flex !important;
    text-align: left !important;
    align-items: center !important;
    justify-content: flex-start !important;
  }
  ul {
    padding: 0 !important;
  }
  .menu_news {
    font-family: remake;
    padding-left: 10vw;
    line-height: 170%;
    letter-spacing: 6%;
    font-size: 1.66vh;
    padding-bottom: 3vh;
  }
  .responsive__menu__social {
    width: 50vw;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 3vw !important;
  }
  .responsive__menu__hr {
    display: flex;
    color: black;
    background-color: black;
    width: 80% !important;
  }
  .socialMenu {
    margin: 3vh 0 3vh 0;
    width: 8vw;
    height: auto;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }
  .nav-links__right {
    padding: 1.5rem;

    display: table;
    color: white;
    text-decoration: none;
    padding: 1.5rem;

    border-bottom: 3px solid transparent;
  }
  .nav-icon {
    display: block;
    position: absolute;
    /* top: 1vh !important; */
    right: 5vw;
    width: 5vw;
    height: auto;

    cursor: pointer;

    /* display: none; */
  }
  .nav-icon2 {
    display: block;
    position: absolute;
    /* top: 1vh; */
    right: 5vw;
    width: 5vw;
    cursor: pointer;
    z-index: 100;
  }
}
@media screen and (max-width: 1000px) {
  .nav-logo {
    position: relative;
    left: 75% !important;
    transform: translateX(-50%) !important;
  }
  .nav-menu {
    position: relative;
    /* top: 2vh; */
    left: 0vw;
  }
  .bar__logo2 {
    width: 40vw;
    height: auto;
    position: relative;
    /* top: 2px !important; */
  }
  .bar__logo {
    /* top: 15px !important; */
    width: 40vw;
    height: auto;
    /* top: 2vh !important; */
  }
  .nav-container {
    padding: 10px 0 10px 0 !important;
  }
  .colorChange {
    padding: 10px 0 10px 0 !important;
  }
  .nav-menu.active {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    height: 100vh;
    position: absolute;
    padding-top: 5vh !important;
    top: 8.8vh;
    right: 0vw;
    background: white;
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
    opacity: 1;
    text-align: left !important;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .nav-item {
    display: none;
  }
  .responsive__menu {
    margin-right: auto;
    display: flex;
    text-align: left !important;
    align-items: center !important;
    justify-content: flex-start !important;
  }
  ul {
    padding: 0 !important;
  }
  .menu_news {
    font-family: remake;
    padding-left: 10vw;
    line-height: 170%;
    letter-spacing: 6%;
    font-size: 1.66vh;
    padding-bottom: 3vh;
  }
  .responsive__menu__social {
    width: 50vw;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 3vw !important;
  }
  .responsive__menu__hr {
    display: flex;
    color: black;
    background-color: black;
    width: 80% !important;
  }
  .socialMenu {
    margin: 3vh 0 3vh 0;
    width: 8vw;
    height: auto;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }
  .nav-links__right {
    padding: 1.5rem;

    display: table;
    color: white;
    text-decoration: none;
    padding: 1.5rem;

    border-bottom: 3px solid transparent;
  }
  .nav-icon {
    display: block;
    position: absolute;
    /* top: 1.8vh !important; */
    right: 5vw;
    width: 5vw;
    height: auto;

    cursor: pointer;

    /* display: none; */
  }
  .nav-icon2 {
    display: block;
    position: absolute;
    /* top: 1.8vh !important; */
    right: 5vw;
    width: 5vw;
    cursor: pointer;
    z-index: 100;
  }
}
@media screen and (max-width: 1919px) {
  .responsive__menu {
    /* display: none; */
  }
}
@media screen and (width: 768px) {
  .colorChange {
    height: 62px;
  }
  .bar__logo {
    top: 10px !important;
  }
  .bar__logo2 {
    top: 0 !important;
  }
  .hamburger-react {
    top: 7px !important;
    height: 60px !important;
  }
  .nav-icon {
    display: block;
    position: absolute;
    /* top: 1.5vh !important; */
    right: 5vw;
    width: 5vw;
    height: auto;

    cursor: pointer;

    /* display: none; */
  }
  .nav-icon2 {
    display: block;
    position: absolute;
    /* top: 1.5vh !important; */
    right: 5vw;
    width: 5vw;
    cursor: pointer;
    z-index: 100;
  }
}

@media screen and (max-width: 320px) {
  .colorChange {
    height: 62px;
  }

  .bar__logo {
    position: relative;
    top: -5px !important;
  }
  .hamburger-react {
    top: -4px !important;
    height: 46px !important;
  }
}
@media screen and (width: 820px) {
  .nav-icon {
    display: block;
    position: absolute;
    /* top: 1.5vh !important; */
    right: 5vw;
    width: 5vw;
    height: auto;

    cursor: pointer;

    /* display: none; */
  }
  .nav-menu {
    top: 8vh !important;
  }
  .hamburger-react {
    height: 70px !important;
  }
  .nav-icon2 {
    display: block;
    position: absolute;
    /* top: 1.5vh !important; */
    right: 5vw;
    width: 5vw;
    cursor: pointer;
    z-index: 100;
  }
  .bar__logo {
    position: relative;
    top: 0vh !important;
  }
}

@media screen and (height: 667px) {
  .bar__logo {
    top: -5px !important;
  }
  .bar__logo2 {
    top: 0px !important;
  }
}
