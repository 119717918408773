.newsection1 {
  /* background-image: linear-gradient(
    to bottom,
    #b73e00,
    #cc680b,
    #df9023,
    #f0b73f,
    #ffde60
  ) !important; */
  background-image: url(./orange.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.section1_pantyBKG {
  position: absolute;
  width: 90vw;
  height: auto;
  left: 50%;
  top: 44%;
  transform: translate(-50%, -50%);
  animation: 1.2s fadeIn;
  animation-fill-mode: forwards;

  visibility: hidden;
}
@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}
@media screen and (max-width: 800px) {
  .newsection1 {
    background-size: cover;
  }
  .section1_pantyBKG {
    position: absolute;
    left: 50%;
    top: 42%;
    transform: translate(-50%, -50%);
    width: 100vw !important;
    height: auto;
  }
}

@media screen and (max-width: 1024px) {
  .newsection1 {
    background-size: cover;
  }
  .section1_pantyBKG {
    position: absolute;
    left: 50%;
    top: 35%;
    transform: translate(-50%, -50%);
  }
}
