.countdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: relative;
  top: 30vh;
  margin-bottom: 60vh;
}
.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.cryptopanties {
  font-size: 60px;
  color: white;
  line-height: 100%;
  /* or 60px */
  margin-bottom: 10px !important;
  text-align: center;
  letter-spacing: 0.06em;
  font-family: alt;
  font-weight: 400;
}
.supporting {
  font-family: alt;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 100%;
  /* identical to box height, or 24px */

  text-align: center;
  letter-spacing: 0.1em;

  color: #ffffff;
}
.countdown-item {
  color: #fff;
  font-weight: lighter !important;
  display: flex;
  font-family: alt !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 50px;
  margin: 0;
  min-width: 10vw;
  position: relative;
}
.colon {
  font-size: 64px;
  position: relative;
  top: 15px;
  color: #fff;
  font-family: alt !important;
}
.countdown-item span {
  color: #333;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.countdown-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
}

footer {
  background-color: #222;
  color: #fff;
  font-size: 14px;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 999;
}

footer p {
  margin: 10px 0;
}

footer i {
  color: red;
}

footer a {
  color: #3c97bf;
  text-decoration: none;
}
h3 {
  font-size: 64px;
  padding: 0vh;
  position: relative;
  top: 40px;
  /* line-height: 82px; */
  text-align: center;
  letter-spacing: 6%;
  font-family: pilat;
  font-weight: 500;
}
h6 {
  font-size: 0.85rem;
  position: relative;
  bottom: 50px;
  /* line-height: 21px; */
  margin-bottom: 0 !important;
  text-align: center;
  letter-spacing: 0.1em;
  font-family: alt;
  font-weight: normal;
}
.vl {
  border-left: 1px solid rgb(255, 255, 255);
  height: 12vh;
}
.section1__disc {
  cursor: pointer;
}
.section1__disc img {
  cursor: pointer;
}
.section1__disc:hover {
  /* background: rgba(0, 0, 0, 0.2); */
  filter: hue-rotate(45deg);
  transition: 0.5s;
}
.section1__news:hover {
  /* background: rgba(0, 0, 0, 0.2); */
  filter: hue-rotate(45deg);
  transition: 0.5s;
}
.section1__news {
  cursor: pointer;
}
.section1__box__btns {
  margin-top: 12vh;
}
.section1__btns {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}
/* @media screen and (height:812px) {
  .cryptopanties{
    font-size:1vh !important;

  }
  .supporting{
    font-size: 1.5vh !important;
    color: white;
  }
} */
@media screen and (max-width: 800px) {
  .countdown-wrapper {
    top: 28vh;
  }
  h3 {
    position: relative;
    top: 3vh !important;
    font-size: 32px !important;
  }
  .colon {
    bottom: 4px !important;
    /* font-size: 3.5vh !important; */
    font-size: 32px !important;
  }
  h6 {
    /* font-size: 1vh !important; */
    position: relative;
    bottom: 3vh !important;
    font-size: 8px !important;
  }

  .countdown-item {
    margin: 0 !important;
    min-width: 15vw !important;
  }
  .cryptopanties {
    font-weight: normal;
    font-size: 32px !important;
    line-height: 100%;
    /* or 22px */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.08em;
  }
  .supporting {
    font-size: 1.5vh !important;
    color: white;
  }
  .section1__disc {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .section1__disc img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .section1__news {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0 !important;
    position: relative;
    bottom: 5vh;
  }
  .section1__news img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .section1__socials {
    text-align: center !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .section1__box__btns {
    text-align: center !important;
    margin-top: 10vh;
  }
  .section1__btns {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }
}

@media (max-width: 400px) {
  .countdown-wrapper {
    padding: 0;
  }
  .colon {
    bottom: 0px !important;
  }
  .countdown-item {
    margin: 5px;
    min-width: 20vw !important;
  }
  .cryptopanties {
    font-size: 28px !important;
  }
}
@media screen and (width: 768px) {
  .cryptopanties {
    font-size: 60px !important;
    line-height: 100%;
    /* or 22px */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.08em;
    color: white;
    font-weight: normal;
  }

  h3 {
    position: relative;
    /* top: 3vh !important; */
    font-size: 64px !important;
  }
  .colon {
    bottom: 11px !important;
    /* font-size: 3.5vh !important; */
    font-size: 64px !important;
  }
  h6 {
    /* font-size: 1vh !important; */
    position: relative;
    bottom: 0 !important;
    font-size: 0.85rem !important;
  }
}
@media screen and (max-width: 1024px) {
  .countdown-wrapper {
    top: 28vh;
    margin-bottom: 50vh;
  }
  .countdown-item {
    min-width: 14vw;
  }
  h3 {
    position: relative;
    /* top: 3vh !important; */
    font-size: 64px;
  }
  .colon {
    bottom: 8px;
    /* font-size: 3.5vh !important; */
    font-size: 64px;
  }
  h6 {
    /* font-size: 1vh !important; */
    position: relative;
    bottom: 3vh !important;
    font-size: 0.85rem;
  }
  .cryptopanties {
    font-weight: normal !important;
    font-size: 60px;
    line-height: 100%;
    /* or 22px */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.08em;
    color: white;
  }
  .supporting {
    font-size: 2vh;
    color: white;
    margin-top: 1vh !important;
  }
  .section1__disc {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .section1__disc img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .section1__news {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0 !important;
    position: relative;
    bottom: 1vh;
  }
  .section1__news img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .section1__socials {
    text-align: center !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .section1__box__btns {
    text-align: center !important;
    margin-top: 12vh;
  }
  .section1__btns {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }
}

/* @media screen and (width: 912px) {
  .cryptopanties {
    font-size: 6vh !important;
  }
}
@media screen and (width: 991px) {
  .cryptopanties {
    font-size: 6vh !important;
  }
} */
